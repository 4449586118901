@import "mixins"
.sportItem
  height: var(--sportsNav_itemHeight)
  @include flex
  flex-direction: column
  justify-content: center
  padding: 0 6px 2px
  margin: var(--sportsNav_itemMargin)
  min-width: var(--sportsNav_itemWidth)
  max-width: var(--sportsNav_itemWidth)
  text-transform: uppercase
  color: var(--sportsNav_itemColor)
  position: relative

  :global(.svg-favorite-icon),
  :global(.svg-sport-icon)
    min-height: 20px
    opacity: 0.7
    margin: 4px 0 8px
    svg
      height: 20px
      width: 20px

.hasFavourite
  opacity: 0.4
  position: absolute
  top: 4px
  left: 6px
  svg
    width: 8px
    height: 8px

.gamesCount
  position: absolute
  top: 4px
  right: 5px
  font-size: 11px

.sportName
  @include ellipsis
  max-width: 100%

.active, .activeBtn
  box-shadow: 0 -1px var(--sportsNav_itemActiveShadow)
  border-radius: var(--sportsNav_itemActiveRadius)
  color: rgb(var(--secondaryColor))
  position: sticky
  left: -4px
  right: -4px
  z-index: 1
  font-weight: var(--sportsNav_itemActiveFontWeight)

  :global(.svg-sport-icon)
    opacity: 1
    svg
      path
        fill: var(--sportsNav_itemActiveIconFill) !important

  :global(.svg-favorite-icon),
  :global(.svg-sport-icon)
    opacity: 1

  .sportName
    color: var(--sportsNav_itemActiveColor)

  &:before,
  &:after
    content: var(--sportsNav_corner)
    mask-image: var(--icon-tab-corner)
    width: 8px
    height: 8px
    position: absolute
    bottom: 1px

  &:before
    left: -8px
    transform: scaleX(-1)

  &:after
    right: -8px

  .hasFavourite
    opacity: 0.7

.active
  background: var(--sportsNav_itemActiveBg)

  &:before,
  &:after
    background-color: var(--body_bg)

.activeBtn
  background: var(--home_tournamentItemBg)

  &:before,
  &:after
    background-color: var(--home_tournamentItemBg)

@media screen and (max-width: 380px) and (orientation: portrait)
  .sportItem
    padding: 0 8px 2px
