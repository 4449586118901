@import "mixins"
.gameItem
  width: calc(50% - 10px)
  max-width: 192px
  flex: none
  margin: 10px 5px 0
  @include flex
  justify-content: center
  justify-self: center
  position: relative

  > div
    width: 100%
    height: 100%
    max-height: 260px
    position: relative
    overflow: hidden
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4)
    background: var(--casino_gameItemBg)
    border: var(--casino_gameItemBorder)
    border-radius: var(--casino_gameItemRadius)
    display: flex
    justify-content: center
    transition: all 0.3s, border 0s

    img, video
      border-radius: var(--casino_gameItemImgRadius)

    :global(.imageNext)
      width: 100%
      position: relative

      img
        width: 100%
        height: auto

    video
      width: 100%
      height: 100%
      object-fit: cover

    &:before
      content: ""
      background: rgba(21, 29, 42, 0.4)
      position: absolute
      width: 100%
      height: calc(100% - var(--casino_gameItemNameHeight) + 1px)
      opacity: 0
      z-index: 1
      transition: all 0.3s

    &:hover .btnsContainer,
    &:hover:before
      opacity: 1

  &:active > div
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.4)

.casinoDropsAndWins
  position: absolute
  left: 0
  top: 0
  z-index: 1
  border-top-left-radius: var(--casino_gameItemDropsAndWinsRadius)
  overflow: hidden

.gameLabel
  position: absolute
  right: -3px
  top: 16%
  z-index: 2
  transition: all 0.2s
  display: flex
  flex-direction: column
  justify-content: flex-end
  > span
    text-transform: uppercase
    @include flex
    justify-content: center
    width: 32px
    height: 18px
    border-radius: 0 2px 2px 0
    margin-top: 6px !important
    position: relative
    background: var(--tagItemColor)

    > object
      height: 14px

    &:before,
    &:after
      content: ""
      width: 0
      height: 0
      border-style: solid
      position: absolute
      left: -6px
    &:before
      border-width: 0 6px 9px 0
      border-color: transparent var(--tagItemColor) transparent transparent
      top: 0
    &:after
      border-width: 0 0 9px 6px
      border-color: transparent transparent var(--tagItemColor) transparent
      bottom: 0
    &.base-icon-casino-new
      --tagItemColor: #20a814
    &.base-icon-casino-popular
      --tagItemColor: #f1ce0f
    &.base-icon-casino-hot
      --tagItemColor: #dc3438
    &.base-icon-casino-top
      --tagItemColor: #106fe0

.unstarred
  min-width: 32px
  height: var(--casino_gameItemNameHeight)
  position: absolute
  z-index: 2
  right: var(--casino_gameItemNamePosition)
  top: 0
  @include flex
  justify-content: center
  &:before
    content: ""
    background-color: var(--casinoCompact_gameItemUnstarredFill)
    @include star(18px)
    transition: all 0.8s
    transform: rotate(0deg)
    transform-origin: center
    animation: unstarred 0.5s ease-in
    pointer-events: none

    @keyframes unstarred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 18px
        height: 18px
        mask-size: 18px 18px

  &.starred:before
    background-color: var(--star_activeBg)
    animation: starred 0.5s ease-in
    transform: rotate(145deg)

    @keyframes starred
      50%
        width: 26px
        height: 26px
        mask-size: 26px 26px
      100%
        width: 18px
        height: 18px
        mask-size: 18px 18px

.gameItemName
  position: absolute
  width: 100%
  height: var(--casino_gameItemNameHeight)
  bottom: var(--casino_gameItemNamePosition)
  left: 0
  border-radius: 0 0 var(--casino_gameItemRadius) var(--casino_gameItemRadius)

  > div
    display: flex
    flex-direction: column
    justify-content: center
    color: rgba(var(--secondaryColor))
    @include bold
    background: var(--casino_gameItemNameBg)
    padding: var(--casino_gameItemNamePadding)
    font-size: var(--casino_gameItemNameFontSize)
    text-align: var(--casino_gameItemNameAlign)
    height: 100%
    text-transform: uppercase
    border-radius: 0 0 var(--casino_gameItemRadius) var(--casino_gameItemRadius)
    position: relative
    z-index: 2
    > span
      @include ellipsis
      margin-bottom: 4px
      color: rgb(var(--secondaryColor))

  &:has(.bluredBg) > div
    background: none

.btnsContainer
  position: absolute
  z-index: 2
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  transition: all 0.3s ease-in-out
  @include flex
  flex-direction: column
  opacity: 0

  button
    text-transform: uppercase
    min-width: var(--casino_gameItemButtonWidth)
    height: 32px
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5)

  .playNowBtn
    background: var(--casinoCompact_playNowBtnBg)
    color: var(--casino_providerActiveColor)

    &:active
      background: var(--casinoCompact_playNowBtnActiveBg)

  .tryButton
    margin-top: 12px
    background: var(--casinoCompact_tryBtnBg)
    color: var(--casinoCompact_tryBtnColor)
    &:active
      background: rgb(var(--casinoCompact_tryBtnActiveBg))

.bluredBg
  position: absolute
  bottom: 1px
  left: 1px
  width: calc(100% - 2px)
  height: var(--casino_gameItemNameHeight)
  backdrop-filter: blur(3px)
  z-index: 2
  overflow: hidden
  border-radius: 0 0 13px 13px

  > span
    width: 100%
    height: 100%
    display: block
    position: absolute
    top: 0
    left: 0
    background: rgba(23, 31, 44, 0.4)
